import { lazy } from 'react'

const DocumentCardLazy = lazy(() =>
  import('./components/DocumentCard/document-card.component').then(
    (module) => ({
      default: module.DocumentCard,
    })
  )
)

const DocumentExampleLazy = lazy(() =>
  import('./components/DocumentExample/document-example.component').then(
    (module) => ({
      default: module.DocumentExample,
    })
  )
)

export { DocumentCardLazy, DocumentExampleLazy }
